.form-list-wrapper {
    max-height: 80vh;
    overflow: auto;
    margin-bottom: 16px;

    .form-list-item {
        display: flex;
        gap: 16px;
        padding: 16px;
        border: 1px solid gray;
        border-radius: 8px;
        margin-bottom: 16px;
    }
}