.project-card-row {
    .ant-list {
        max-height: 300px;
        overflow-y: auto;
    }
}

.new-survey-form {
    display: flex;
    gap: 32px;
    padding: 16px;
    border: 1px dashed #1677ff;
    border-radius: 8px;
    margin-bottom: 16px;

    >.ant-row {
        width: 100%;
    }

    .ant-radio-group {
        display: flex;

        >label {
            text-align: center;
        }
    }
}