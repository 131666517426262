.editable-list-wrapper {

    margin: 16px 0px;
    row-gap: 32px;

    div {
        display: flex;
        height: 100%;
    }

    .editable-list-title {
        font-size: 20px;
        font-weight: 600;
    }

    .ant-list-empty-text {
        justify-content: center;
        align-items: center;
        width: 100%;

        * {
            flex-direction: column;
        }
    }

    .list-wrapper {
        flex-direction: column;

        .header {
            margin-bottom: 24px;
        }

        .list {
            overflow-y: auto;
            height: 350px;

            .delete-field-button-wrapper {
                color: red;
            }

            .ant-spin-nested-loading,
            .ant-spin-container,
            .ant-list-items {
                width: 100%;
            }

            .ant-list-item {

                &.selected-item {
                    >div {
                        align-items: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 90%;
                        display: unset;
                    }
                }

                .ant-checkbox-wrapper {
                    width: 100%;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: space-between;

                    >span:last-child {
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 90%;
                        text-overflow: ellipsis;
                    }

                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}