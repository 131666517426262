.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    h2,
    h3 {
        margin: 0;
        line-height: 10px;
    }
}

.auth-wrapper {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	
	.auth-card{
		max-width: 600px;
		width: 90%;
	}
}
  