.successful-message,
.is-post-answer-successful-message-wrapper {
	display: flex;
	height: 100%;
	width: 100%;

	align-items: center;
	justify-content: center;

}

.successful-message {

	max-height: 500px;
	max-width: 450px;

	padding: 16px;
	border-radius: 8px;
	background-color: white;

	flex-direction: column;
	justify-content: flex-start;

	text-align: center;

	.icon {
		margin: 0;
		color: #1890ff;
		font-size: 220px;
	}
}