.daily-bot-header {
  display: flex;
  position: sticky;
  z-index: 1;
  top: 0;

  .header-logo {
    color: white;
    min-width: 198px;

    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    span {
      color: white;
    }

    span:first-child {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }

    span:last-child {
      font-size: 16px;
      line-height: 21px;
    }
  }

  ul {
    width: 100%;
  }
}

.main-content {
  padding: 16px;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  display: flex;

  >.ant-spin-nested-loading {
    display: flex;
    flex-grow: 1;

    >.ant-spin-container {
      flex-grow: 1;
    }
  }
}