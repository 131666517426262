.card-default-list-title {
    display: flex;
    gap: 16px;
}

.default-list {
    max-height: 50vh;
    overflow-y: auto;

    .default-list-item {
        display: flex;
        flex-direction: column;
        align-items: unset;
        cursor: pointer;
        padding: 0;
       
        >span{
            max-width: 90%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .default-list-item:hover {
        transition: 0.1s;
        background-color: rgb(241, 241, 241);
    }
}